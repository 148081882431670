<template>
  <div class="page-sub-box ewm">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="page_search_box line_bottom_1px">
        <div class="inner alL flex items-center">
          <div>등록일</div>
          <div class="ui-datepicker-item">
            <DxDateBox
                styling-mode="outlined"
                :width="120"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy.MM.dd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :max="searchType.customTypes.dayEnd"
                maxLength="10"
                v-model="searchType.customTypes.dayStart"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기간은 필수입니다."/>
                <DxRangeRule :max="searchType.customTypes.dayEnd" message="시작일은 종료일보다 작거나 같아야 합니다."/>
              </DxValidator>
            </DxDateBox>
          </div>
          <div class="">
            <span class="dash">~</span>
          </div>
          <div class="ui-datepicker-item">
            <DxDateBox
                styling-mode="outlined"
                :width="120"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy.MM.dd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                maxLength="10"
                :min="searchType.customTypes.dayStart"
                v-model="searchType.customTypes.dayEnd"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기간은 필수입니다."/>
                <DxRangeRule :min="searchType.customTypes.dayStart" message="종료일은 시작일보다 크거나 같아야 합니다."/>
              </DxValidator>
            </DxDateBox>
          </div>
          <div>
            출제횟수
          </div>
          <DxNumberBox
              placeholder="최소"
              :styling-mode="stylingMode"
              v-model="searchType.fromQuestionCnt"
              :width="50"
              :height="30"
              :min="0"
              :max="searchType.toQuestionCnt"
              class="mar_ri10"
          >
          </DxNumberBox>

          <div class="ui-datepicker period">
            <span class="dash">~</span>
          </div>
          <DxNumberBox
              placeholder="최대"
              :styling-mode="stylingMode"
              v-model="searchType.toQuestionCnt"
              :width="50"
              :height="30"
              :min="searchType.toQuestionCnt"
              class="mar_ri10"
          >
          </DxNumberBox>

          <DxButton
              text="검색"
              class="btn_M box-btn-search"
              type="button"
              :height="30"
              @click="selectDataList"
              validation-group="validationSearch"
          />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="content-box flex">
        <div v-bind:class="leftBoxClassName" ref="leftBox">
          <esp-dx-data-grid :data-grid="dataGrid" ref="questionGrid" @row-click="onRowClick"></esp-dx-data-grid>
        </div>

        <Transition name="slide-right">
          <div v-show="showForm" v-bind:class="rightBoxClassName" ref="rightBox" class="overflow-auto">
            <AnswerForm
                :contentData="modal.contentData"
                v-model="modal.formData"
                ref="answerForm"
                class="agent-form-box"
                @onCloseAnswerForm="onForm"
                @onSelectList="selectDataList"
            />
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import {DxButton} from 'devextreme-vue/button';
import {DxDateBox} from 'devextreme-vue/date-box';
import {DxNumberBox} from 'devextreme-vue/number-box';
import {DxPopover} from 'devextreme-vue/popover';
import {DxValidator, DxRequiredRule, DxRangeRule} from 'devextreme-vue/validator';
import AnswerForm from '@/pages/ewm/exam/question/form.vue';
import validationEngine from 'devextreme/ui/validation_engine';

import {getPastFromToday, isSuccess, setGridSingleSelection} from '@/plugins/common-lib';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";


let vm = this;
export default {
  components: {
    EspDxDataGrid,
    DxButton,
    DxDateBox,
    DxNumberBox,
    AnswerForm,
    DxRequiredRule,
    DxRangeRule,
    DxValidator,
  },
  data() {
    return {
      codeMap: {},
      dataGridLoopup: {
        questionDivisionCd: {
          dataSource: [],
          displayExpr: 'codeNm',
          valueExpr: 'codeId',
        },
        questionDivisionDetailCd: {
          dataSource: [],
          displayExpr: 'codeNm',
          valueExpr: 'codeId',
        },
      },
      stylingMode: 'outlined',
      rightBoxClassName: 'right-box',
      leftBoxClassName: 'left-box',
      rowIndex: null,
      attachFileUrl: {
        fileUpload: 'COMMON_ATTACHED_FILE_UPLOAD',
        fileDownload: 'COMMON_ATTACHED_FILE_DOWNLOAD',
        fileDelete: 'COMMON_ATTACHED_FILE_DELETE',
        fileDeleteAll: 'COMMON_ATTACHED_FILE_GROUP_DELETE',
        fileCopy: 'COMMON_ATTACHED_FILE_COPY',
      },
      searchType: {
        customTypes: {
          dayStart: getPastFromToday(30, 'days'),
          dayEnd: getPastFromToday(0, 'days'),
        },
        fromQuestionCnt: null,
        toQuestionCnt: null,
      },
      showForm: false,
      modal: {
        contentData: {
          questionDivisionCd: null,
          questionDivisionDetailCd: null,
          levelCd: null,
          answerTypeCd: null,
          viewFl: null,
          scoringTypeCd: null,
          scroe: 0,
          qustionNm: null,
          description: null,
          answers: [],
        },
        formData: null,
      },
      dataGrid: {
        refName: 'questionGrid',
        height: 'calc(100vh - 370px)',
        callApi: 'CALL_EWM_API',
        allowColumnResizing: true, //컬럼 사이즈 허용
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        hoverStateEnabled: true,
        dataSource: [],
        apiActionNm: {
          //select: 'EWM_EXAM_QUESTION_LIST',
        },
        customEvent: {
          rowClick: true,
        },
        showActionButtons: {
          //delete: true,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '추가',
                elementAttr: {class: 'btn_XS default filled add1'},
                width: 60,
                height: 30,
                onClick() {
                  vm.onForm(true).then(() => {
                    vm.$refs.answerForm.initForm();
                  });
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '삭제',
                elementAttr: {class: 'btn_XS white light_filled trash'},
                width: 60,
                height: 30,
                onClick() {
                  vm.onDeleteData();
                },
              },
              location: 'before',
            },
          ],
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        scrolling: {
          mode: 'virtual',
        },
        paging: {
          enabled: false,
        },
        pager: {
          visible: false, //페이저 표시 여부
        },
        filterRow: {
          visible: true,
          operationDescriptions: {
            contains: '포함',
          },
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
        },
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: '아이디',
            dataField: 'id',
            visible: false,
            cellTemplate: (container, options) => {
              container.innerText = options.value;
            },
          },
          {
            caption: '문항구문',
            dataField: 'questionDivisionCd',
            alignment: 'center',
            allowFiltering: true,
            //allowEditing: true,
            lookup: {},
            calculateSortValue: data => vm.calculateSortValue(data, 'questionDivisionCd'),
          },

          {
            caption: '문항구문상세',
            dataField: 'questionDivisionDetailCd',
            alignment: 'center',
            allowFiltering: true,
            lookup: {},
            calculateSortValue: data => vm.calculateSortValue(data, 'questionDivisionDetailCd'),
          },
          {
            caption: '문항내용',
            dataField: 'qustionNm',
            width: 350,
            alignment: 'left',
            allowFiltering: true,
            cellTemplate: (container, options) => {
              if (options.value) {
                container.innerText = options.value.replace(/(<([^>]+)>)/gi, '');
              }
            },
          },
          {
            caption: '첨부',
            dataField: 'fileGroupId',
            width: 130,
            height: 40,
            aligment: 'center',
            allowFiltering: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowSorting: false,
            cellTemplate: (container, options) => {
              const fileGroupId = options.value;
              const originalFileName = options.data.originalFileName;
              if (fileGroupId) {
                // 다운로드 버튼 추가
                let btnArea = document.createElement('div');
                btnArea.style = 'text-align:center';
                const downBtn = new DxButton({
                  propsData: {
                    icon: 'download',
                    hint: '다운로드',
                    onClick: e => {
                      e.event.stopPropagation();
                      vm.onDataDownload(options.data);
                    },
                  },
                });
                downBtn.$mount();
                btnArea.appendChild(downBtn.$el);

                // mouse hover effect
                const fileInfoPopover = new DxPopover({
                  propsData: {
                    target: btnArea,
                    contentTemplate: `<div>${originalFileName}</div>`,
                    position: 'top',
                    showEvent: 'mouseenter',
                    hideEvent: 'mouseleave',
                  },
                });
                fileInfoPopover.$mount();
                btnArea.appendChild(fileInfoPopover.$el);
                container.append(btnArea);
              }
            },
          },
          {
            caption: '첨부파일명',
            dataField: 'originalFileName',
            visible: false,
          },
          {
            caption: '정답유형',
            dataField: 'answerType',
            alignment: 'center',
            allowHeaderFiltering: true,
            lookup: {
              dataSource: this.$_enums.ewm.answerType.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
            calculateSortValue: data => vm.calculateSortValue(data, 'answerType'),
          },
          {
            caption: '난이도',
            width: 100,
            dataField: 'examLevel',
            alignment: 'center',
            allowHeaderFiltering: true,
            lookup: {
              dataSource: this.$_enums.ewm.examLevel.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
            calculateSortValue: data => vm.calculateSortValue(data, 'examLevel'),
          },
          {
            caption: '채점방식',
            dataField: 'examScoringType',
            alignment: 'center',
            allowHeaderFiltering: true,
            lookup: {
              dataSource: this.$_enums.ewm.examScoringType.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
            calculateSortValue: data => vm.calculateSortValue(data, 'examScoringType'),
          },
          {
            caption: '사용여부',
            width: 120,
            dataField: 'useFl',
            alignment: 'center',
            allowHeaderFiltering: true,
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
            calculateSortValue: data => vm.calculateSortValue(data, 'viewFl'),
          },
          {
            caption: '출제횟수',
            width: 80,
            dataField: 'questionCnt',
            alignment: 'center',
            allowFiltering: true,
            allowHeaderFiltering: false,
          },
          {
            caption: '배점',
            width: 80,
            dataField: 'scroe',
            alignment: 'center',
            allowFiltering: true,
            allowHeaderFiltering: false,
          },
          {
            caption: '정답해설',
            dataField: 'description',
            alignment: 'center',
            visible: false,
          },
          {
            caption: '등록자',
            dataField: 'regId',
            alignment: 'center',
            allowFiltering: false,
            allowHeaderFiltering: true,
            visible: false,
          },
          {
            caption: '등록자',
            dataField: 'regAgtInfo',
            alignment: 'center',
            allowFiltering: true,
            allowHeaderFiltering: false,
            allowSorting: true,
            calculateCellValue: rowData => {
              return `${rowData.regNm}[${rowData.regId}]`;
            },
          },
          {
            caption: '등록일',
            dataField: 'regDt',
            alignment: 'center',
            allowFiltering: true,
            allowHeaderFiltering: false,
            dataType: 'date',
            format: 'yyyy-MM-dd',
            /* cellTemplate: (container, option) => {
              let tag = document.createElement('div');
              let value = `${vm.regFormatDt(option.data.regDt)}`;
              tag.innerHTML = value;
              container.append(tag);
            }, */
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    /** @description : 소트설정 */
    calculateSortValue(data, clsCd) {
      if (clsCd == 'questionDivisionCd') {
        return this.dataGridLoopup.questionDivisionCd.dataSource.find(e => data.questionDivisionCd == e.codeId).codeNm;
      } else if (clsCd == 'questionDivisionDetailCd') {
        const questionDivisionDetailCdDataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_question_division'], 3);

        return questionDivisionDetailCdDataSource.find(e => data.questionDivisionDetailCd == e.codeId).codeNm;
      } else if (clsCd == 'answerType') {
        return this.$_enums.ewm.answerType.values.find(e => data.answerType == e.value).label;
      } else if (clsCd == 'examLevel') {
        return this.$_enums.ewm.examLevel.values.find(e => data.examLevel == e.value).label;
      } else if (clsCd == 'examScoringType') {
        return this.$_enums.ewm.examScoringType.values.find(e => data.examScoringType == e.value).label;
      } else if (clsCd == 'viewFl') {
        return this.$_enums.common.stringUsedFlag.values.find(e => data.viewFl == e.value).label;
      }
    },
    validateData() {
      const validationResult = validationEngine.validateGroup('validationSearch');
      if (!validationResult.isValid) {
        /* const firstError = validationResult.brokenRules[0];
        const result = this.$_Msg(firstError.message);
        result.then(() => {
          setTimeout(() => {
            firstError.validator.focus();
          }, 500);
        }); */
        return false;
      }

      return true;
    },
    async onRowClick(row) {
      setGridSingleSelection(row);

      if (row.rowType != 'data') {
        return;
      }

      const rowdata = row?.data;
      const id = rowdata?.id;
      const isSameRow = this.isSameRow(row);

      if (isSameRow) {
        await this.onForm(false);
      } else {
        this.onForm(true)
            .then(() => {
              return vm.getDetailInfo(id);
            })
            .then(detailInfo => {
              this.onLoadAnswerFormData(rowdata, detailInfo);
            });
        vm.rowIndex = row?.rowIndex;
      }
    },
    isSameRow(row) {
      const rowIndex = row?.rowIndex;
      if (vm.rowIndex == rowIndex) {
        return true;
      } else {
        return false;
      }
    },
    async onForm(isShow) {
      if (isShow) {
        this.leftBoxClassName = 'left-box-75';
        this.rightBoxClassName = 'right-box-25';
        this.$refs.questionGrid.getGridInstance.columnOption('regAgtInfo', 'visible', false);
        this.$refs.questionGrid.getGridInstance.columnOption('regDt', 'visible', false);
        this.$refs.questionGrid.getGridInstance.columnOption('fileGroupId', 'visible', false);
      } else {
        this.leftBoxClassName = 'left-box';
        this.rightBoxClassName = 'right-box';
        this.rowIndex = null;
        this.$refs.questionGrid.getGridInstance.columnOption('regAgtInfo', 'visible', true);
        this.$refs.questionGrid.getGridInstance.columnOption('regDt', 'visible', true);
        this.$refs.questionGrid.getGridInstance.columnOption('fileGroupId', 'visible', true);
      }
      this.showForm = isShow;
    },
    async onOpenForm(isShow) {
      this.showForm = isShow;
    },
    onCloseForm() {
      this.showForm = false;
    },
    onLoadAnswerFormData(rowdata, detailInfo) {
      this.$refs.answerForm.loadFormData(rowdata, detailInfo);
    },
    onDataDownload(optionData) {
      const fileGroupId = optionData.fileGroupId;
      const fileName = optionData.fileName;
      this.$_downloadAttachFile(fileGroupId, fileName);

    },
    async getAttachFileList(fileGroupId) {
      let payload = {
        actionname: 'COMMON_ATTACHED_FILE_LIST',
        path: '/' + fileGroupId,
      };

      return await this.CALL_API(payload);
    },
    async onDeleteData() {
      const selectedRowsData = this.$refs.questionGrid.selectedRowsData;

      if (!selectedRowsData?.length) {
        this.$_Msg('대상이 선택되어 있지 않습니다.');
        return false;
      }

      const isRegistered = element => element.questionCnt > 0;
      if (selectedRowsData.some(isRegistered)) {
        this.$_Msg('출제횟수가 있는 문항에 대해서는 삭제 및 수정이 불가능 합니다.');
        return false;
      }

      if (
          await this.$_Confirm('관련 설정이 모두 삭제됩니다.', {
            title: `<h2 class="red">주의</h2>`,
          })
      ) {
        let idList = [];
        selectedRowsData.some(items => {
          idList.push(items.id);
        });

        const payload = {
          actionname: 'EWM_EXAM_QUESTIONS_DELETE',
          data: {
            data: {
              idList: idList,
            },
          },
          loading: false,
        };
        const res = await this.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          await this.selectDataList();
        } else {
          this.$_Msg('삭제 실패');
        }
      }
    },

    async selectDataList() {
      if (!this.validateData()) {
        //this.$_Msg("기간을 확인해주세요.");
        return;
      }
      // 출제횟수
      const fromQuestionCnt = this.searchType.fromQuestionCnt;
      const toQuestionCnt = this.searchType.toQuestionCnt;

      /* if (fromQuestionCnt != null && !this.$_commonlib.checkOnlyNumber(this.searchType.fromQuestionCnt)) {
        this.$_Msg('숫자만 입력 가능합니다.');
        return false;
      }

      if (toQuestionCnt != null && !this.$_commonlib.checkOnlyNumber(this.searchType.toQuestionCnt)) {
        this.$_Msg('숫자만 입력 가능합니다.');
        return false;
      }

      if (!this.$_commonlib.isEmpty(fromQuestionCnt) && !this.$_commonlib.isEmpty(toQuestionCnt) && fromQuestionCnt > toQuestionCnt) {
        this.$_Msg('잘못된 출제횟수 범위입니다.');
        return false;
      } */

      // 기간
      const startDt = this.$_commonlib.formatDate(this.searchType.customTypes.dayStart).format('YYYYMMDD');
      const endDt = this.$_commonlib.formatDate(this.searchType.customTypes.dayEnd).format('YYYYMMDD');

      let params = {
        frRegDt: startDt,
        toRegDt: endDt,
      };
      if (fromQuestionCnt) {
        Object.assign(params, {fromQuestionCnt: fromQuestionCnt});
      }

      if (toQuestionCnt) {
        Object.assign(params, {toQuestionCnt: toQuestionCnt});
      }

      const payload = {
        actionname: 'EWM_EXAM_QUESTION_SELECT',
        data: params,
        loading: false,
      };

      const res = await this.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.dataGrid.dataSource = res.data.data;
      }
    },
    async getDetailInfo(questionId) {
      const payload = {
        actionname: 'EWM_EXAM_QUESTION_SELECT',
        data: {
          questionId: questionId,
        },
        loading: false,
      };

      const res = await this.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        if (res.data?.data?.length) {
          return res.data.data;
        }
      }
    },
    /** @description : 코드 로드 */
    async initCodeMap() {
      this.codeMap = await this.$_getCodeMapList('root_ewm_edu_exam_question_division');
    },
    createdData() {
      vm = this;

      this.initCodeMap().then(() => {
        this.dataGridLoopup.questionDivisionCd.dataSource = this.$_fetchCodesByDepth(
            this.codeMap['root_ewm_edu_exam_question_division'],
            2,
        );
        this.dataGridLoopup.questionDivisionDetailCd.dataSource = options => {
          return {
            store: this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_edu_exam_question_division']),
            filter: options.data ? ['parentId', '=', options.data.questionDivisionCd] : null,
          };
        };

        this.$refs.questionGrid.getGridInstance.columnOption(
            'questionDivisionCd',
            'lookup',
            this.dataGridLoopup.questionDivisionCd,
        );
        this.$refs.questionGrid.getGridInstance.columnOption(
            'questionDivisionDetailCd',
            'lookup',
            this.dataGridLoopup.questionDivisionDetailCd,
        );
      });
    },
    mountData() {
      this.selectDataList();
    },
  },
  created() {
    this.createdData();
  },
  mounted() {
    this.mountData();
  },
};
</script>

<style lang="scss" scoped>
//.page_search_box .inner div {
//display: inline-block;
//}
.page_search_box .inner > div:not(.box-btn-search) {
  vertical-align: middle;
  margin-right: 10px;
}

.page_search_box .spec-type-box {
  display: inline-block;
}

.page_search_box .inner .box-btn-search {
  margin-right: 15px;
}

.page_search_box #searchTooltip {
  margin-right: 5px;
  font-size: 22px;
  color: #808080;
}

::v-deep {
  .teacher-list li {
    margin: 0.7em 0.5em;
  }
}

//.formArea {
//  float: right;
//  border: 1px solid gray;
//  z-index: 99999;
//  min-width: 40%;
//  text-align: center;
//  background-color: #fff;
//  //min-height: 850px;
//}

.content-box {
  min-height: 800px;
  height: calc(100vh - 370px);
}

.left-box {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  padding-right: 10px;
  /*float: left;*/
}

.left-box-75 {
  /* width: 70%; */
  height: 100%;
  margin-top: 20px;
  padding-right: 10px;
  /* float: left; */
}

.right-box {
  //display: none;
  width: 30%;
  height: 100%;
  visibility: hidden;
  border-left: 2px solid #dddddd;
  margin-top: 20px;
  padding-left: 10px;
  position: relative;
  /* float: right; */
}

.right-box-25 {
  /* width: 30%; */
  min-width: 630px;
  height: 100%;
  border-left: 2px solid #dddddd;
  margin-top: 20px;
  padding-left: 10px;
  position: relative;
  /* float: right; */
}

.left-box {
  transition: all 0.1s ease;
}

.left-box-75 {
  transition: all 0.1s ease;
}

.slide-right-enter-active {
  transition: all 0.1s ease;
}

.slide-right-leave-active {
  transition: all 1ms ease;
}

.slide-right-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-right-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
